import { Features } from 'constants/feature_names'
import { useFeatureFlag } from 'hooks/feature_flag'
import { useMemo } from 'react'

const useCobaltPricing = () => {
  const isAsinLimitsPhase2Enabled = useFeatureFlag(
    Features.COBALT_SHARE_AND_TRENDS_ASIN_LIMITS_PHASE_2
  )
  const isMonthlyAsinLimitsEnabled = useFeatureFlag(
    Features.COBALT_MONTHLY_ASIN_LIMITS
  )
  const isTokenizationEnabled = useFeatureFlag(Features.COBALT_TOLKIEN_PHASE_1)

  const isOnMonthlyAsinPlan =
    isMonthlyAsinLimitsEnabled ||
    (!isTokenizationEnabled && !isAsinLimitsPhase2Enabled)

  const isOnTokenPlan = !isOnMonthlyAsinPlan && isTokenizationEnabled

  const isOnLegacyAsinLimitPlan =
    !isOnMonthlyAsinPlan && !isOnTokenPlan && isAsinLimitsPhase2Enabled

  const pricing = useMemo(
    () => ({
      isOnMonthlyAsinPlan: isOnMonthlyAsinPlan || false,
      isOnTokenPlan: isOnTokenPlan || false,
      isOnLegacyAsinLimitPlan: isOnLegacyAsinLimitPlan || false
    }),
    [isOnLegacyAsinLimitPlan, isOnMonthlyAsinPlan, isOnTokenPlan]
  )

  return pricing
}
export default useCobaltPricing
